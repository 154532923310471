import React, { forwardRef, useRef, useEffect, useState, useCallback } from 'react'
import cx from 'classnames'
import { createPortal } from 'react-dom'
import { useSSR, useUniqueId } from 'hooks'
import { useDevice } from 'device'
import logger from 'logger'
import { useIntl } from 'intl'

import { Icon } from 'components/dataDisplay'
import { buttonMessages } from 'components/inputs'

import createTooltip from '../util/createTooltip'

import s from './Hint.module.css'


type HintProps = {
  id: string
  body: React.ReactElement
  style?: 'dark'
  className?: string
  side?: 'bottom-left'
  visible?: boolean
  ignoreBlur?: boolean
  ignoreScroll?: boolean
  ignoreClick?: boolean
  ignoreTouchStart?: boolean
  hide: () => void
  onShow?: () => void
  onHide?: () => void
}

type HintWrapperProps = Omit<HintProps, 'id' | 'hide'> & {
  children: React.ReactElement
  showOnHover: boolean
  showOnClick: boolean
  showOnFocus: boolean
}

const Hint = forwardRef<HTMLDivElement, HintProps>((props, ref) => {
  const { id, body, style, className, side, hide } = props

  const intl = useIntl()

  const bgStyle = {
    'bg-black text-white': style === 'dark',
  }
  const rootClassName = cx(
    s.hint,
    'absolute p-16 shadow',
    bgStyle,
    side?.includes('bottom') ? 'mt-24' : undefined,
    className
  )

  return createPortal(
    <div ref={ref} id={id} className={rootClassName} role="tooltip" >
      <div className="rounded-2 absolute right-[14rem] top-[-4rem] size-[24rem] rotate-45 bg-black" />

      <button
        type="button"
        className="absolute right-[13rem] top-16 cursor-pointer"
        title={intl.formatMessage(buttonMessages.remove)}
        onClick={hide}
      >
        <Icon name="16/close" />
      </button>

      {side?.includes('bottom') && (
        <div
          className={
            cx(
              '-z-1 rounded-2 absolute top-0 size-[34rem] origin-top rotate-45',
              bgStyle,
              side === 'bottom-left' ? 'right-0' : 'left-1/2'
            )
          }
        />
      )}
      {body}
    </div>,
    document.getElementById('tooltips')
  )
})

const HintWrapper: React.FC<HintWrapperProps> = (props) => {
  const { children, body, style, onShow, onHide, visible, className, side, ignoreBlur, ignoreScroll, ignoreClick, ignoreTouchStart, showOnClick, showOnHover, showOnFocus } = props

  const isSSR = useSSR()
  const { isMobile } = useDevice()

  const id = useUniqueId('hint')
  const triggerRef = useRef<HTMLElement>()
  const hintRef = useRef<HTMLDivElement>()
  const onShowRef = useRef<typeof onShow>(null)
  onShowRef.current = onShow
  const onHideRef = useRef<typeof onHide>(null)
  onHideRef.current = onHide

  const [ isClosed, setIsClosed ] = useState(false)

  const hide = useCallback(() => {
    setIsClosed(true)
  }, [])

  const isVisible = visible && !isClosed

  useEffect(() => {
    if (isSSR) {
      return
    }

    if (!triggerRef.current) {
      logger.error({ children, body }, 'triggerRef for Hint is undefined')

      return
    }

    return createTooltip({
      trigger: triggerRef.current,
      tooltip: hintRef.current,
      styles: s,
      isMobile,
      onShow: onShowRef.current,
      onHide: onHideRef.current,
      visible: isVisible,
      side,
      showOnHover,
      showOnClick,
      showOnFocus,
      ignoreBlur,
      ignoreScroll,
      ignoreClick,
      ignoreTouchStart,
    })
  }, [ children, body, isMobile, isSSR, isVisible, side, ignoreBlur, ignoreScroll, ignoreTouchStart, ignoreClick, showOnHover, showOnClick, showOnFocus ])

  return (
    <>
      {
        !isSSR && (
          <Hint
            ref={hintRef}
            id={id}
            body={body}
            style={style}
            className={className}
            side={side}
            hide={hide}
          />
        )
      }
      {
        React.cloneElement(children, {
          ref: triggerRef,
          'aria-describedby': id,
        })
      }
    </>
  )
}


export default React.memo(HintWrapper)
