import React, { forwardRef, useRef, useEffect } from 'react'
import cx from 'classnames'
import { createPortal } from 'react-dom'
import { useSSR, useUniqueId } from 'hooks'
import { useDevice } from 'device'
import logger from 'logger'

import { Text } from 'components/dataDisplay'

import createTooltip from '../util/createTooltip'

import s from './Tooltip.module.css'


type TooltipProps = {
  id: string
  title?: string | Intl.Message
  text: string | Intl.Message
  style?: 'white' | 'light-beige' | 'dark'
  onShow?: () => void
  onHide?: () => void
  visible?: boolean
  className?: string
  side?: 'bottom-left'
  ignoreBlur?: boolean
  ignoreScroll?: boolean
  ignoreClick?: boolean
  ignoreTouchStart?: boolean
}

const Tooltip = forwardRef<HTMLDivElement, TooltipProps>((props, ref) => {
  const { id, title, text, style, className, side } = props

  const { isDesktop } = useDevice()

  const bgStyle = {
    'bg-white rounded': style === 'white',
    'bg-light-beige': style === 'light-beige',
    'bg-black text-white': style === 'dark',
  }
  const rootClassName = cx(
    s.tooltip,
    'absolute p-16 shadow',
    bgStyle,
    side?.includes('bottom') ? 'mt-32' : undefined,
    side?.includes('left') && isDesktop ? 'ml-16' : undefined,
    className
  )

  return createPortal(
    <div ref={ref} id={id} className={rootClassName} role="tooltip" >
      {side?.includes('bottom') && (
        <div
          className={
            cx(
              '-z-1 rounded-2 absolute top-0 size-[34rem] origin-top rotate-45',
              bgStyle,
              side === 'bottom-left' ? 'right-0' : 'left-1/2'
            )
          }
        />
      )}
      {
        Boolean(title) && (
          <Text className="mb-8" message={title} style="sh5" html />
        )
      }
      <Text message={text} style="p4" html />
    </div>,
    document.getElementById('tooltips')
  )
})

const TooltipWrapper: React.FunctionComponent<Omit<TooltipProps, 'id'> & { children: React.ReactElement }> = (props) => {
  const { children, title, text, style, onShow, onHide,
    visible, className, side, ignoreBlur, ignoreScroll, ignoreClick, ignoreTouchStart } = props

  const { isMobile } = useDevice()

  const id = useUniqueId('tooltip')
  const triggerRef = useRef<HTMLElement>()
  const tooltipRef = useRef<HTMLDivElement>()
  const onShowRef = useRef<typeof onShow>(null)
  onShowRef.current = onShow
  const onHideRef = useRef<typeof onHide>(null)
  onHideRef.current = onHide


  const isSSR = useSSR()

  useEffect(() => {
    if (isSSR) {
      return
    }

    if (!triggerRef.current) {
      logger.error({ children, text }, 'triggerRef for Tooltip is undefined')

      return
    }

    return createTooltip({
      trigger: triggerRef.current,
      tooltip: tooltipRef.current,
      styles: s,
      isMobile,
      onShow: onShowRef.current,
      onHide: onHideRef.current,
      visible,
      side,
      ignoreBlur,
      ignoreScroll,
      ignoreClick,
      ignoreTouchStart,
    })
  }, [ children, text, isMobile, isSSR, visible, side, ignoreBlur, ignoreScroll, ignoreTouchStart, ignoreClick ])

  return (
    <>
      {
        !isSSR && (
          <Tooltip
            ref={tooltipRef}
            id={id}
            title={title}
            text={text}
            style={style}
            className={className}
            side={side}
          />
        )
      }
      {
        React.cloneElement(children, {
          ref: triggerRef,
          'aria-describedby': id,
        })
      }
    </>
  )
}


export default React.memo(TooltipWrapper)
