import React, { useRef, useEffect, useCallback } from 'react'
import { twcx } from 'helpers'

import { CloseIconButton } from 'components/inputs'

import s from './Notification.module.css'


export type NotificationProps = {
  className?: string
  closeNotification?: () => void
  timeout?: number
  withAnimation?: boolean
  underPopups?: boolean
  onClick?: () => void
  onClose?: () => void
}

const Notification: React.CFC<NotificationProps> = (props) => {
  const {
    children,
    className,
    closeNotification,
    timeout = 8000,
    withAnimation = true,
    underPopups = false,
    onClick,
    onClose,
  } = props

  const nodeRef = useRef<HTMLDivElement>()

  const handleClose = useCallback(() => {
    if (typeof closeNotification === 'function' && nodeRef.current) {
      nodeRef.current.style.marginTop = `${-1 * nodeRef.current.clientHeight}px`
      nodeRef.current.classList.add(s.closed)
      setTimeout(closeNotification, 300)

      if (typeof onClose === 'function') {
        onClose()
      }
    }
  }, [ closeNotification, onClose ])

  useEffect(() => {
    if (!timeout) {
      return
    }

    const timer = setTimeout(handleClose, timeout)

    return () => {
      clearTimeout(timer)
    }
  }, [ handleClose, timeout ])

  const handleClick = useCallback((event) => {
    event.stopPropagation()

    if (typeof onClick === 'function') {
      onClick()
    }

    handleClose()
  }, [ handleClose, onClick ])

  const handleCloseIconClick = useCallback((event) => {
    event.stopPropagation()

    handleClose()
  }, [ handleClose ])

  const rootClassName = twcx(
    s.notification,
    withAnimation && s.notificationWithAnimation,
    underPopups && s.notificationUnderPopups,
    'z-2 relative left-0 top-0 select-none rounded bg-white py-32 pl-32 pr-48 shadow',
    className
  )

  return (
    <div
      ref={nodeRef}
      className={rootClassName}
      role="alert"
      data-testid="notification"
      onClick={handleClick}
    >
      <CloseIconButton
        className="right-16 top-16"
        onClick={handleCloseIconClick}
      />
      {children}
    </div>
  )
}


export default Notification
