import React, { createContext, useCallback, useEffect, useRef } from 'react'
import { createPortal } from 'react-dom'
import TrapFocus from 'trap-focus'
import { usePathname } from 'router'
import { useDevice } from 'device'
import { twcx } from 'helpers'
import { useFreezeBodyScroll, useIsomorphicLayoutEffect, useUniqueId } from 'hooks'

import { Overlay } from 'components/layout'
import type { CloseIconButtonProps } from 'components/inputs'
import { CloseIconButton } from 'components/inputs'

import s from './PlainModal.module.css'


export const ModalContext = createContext(null)

export type PlainModalProps = {
  children?: React.ReactNode
  className?: string
  containerClassName?: string
  overlayClassName?: string
  underMobileHeader?: boolean
  overlayClosable?: boolean
  withCloseButton?: boolean
  withAnimation?: boolean
  shouldCloseOnPathnameChange?: boolean
  closeButtonIcon?: CloseIconButtonProps['icon']
  closeButtonClassName?: string
  closeModal: (withOnClose?: boolean) => void
  bgColor?: keyof typeof bgColorToClassName
  type?: 'default' | 'sidebar-left' | 'sidebar-right' | 'fullscreen-top' | 'bottom'
  'data-testid': string
}

export const bgColorToClassName = {
  'white': 'bg-white',
  'gray-10': 'bg-gray-10',
  'dark-beige': 'bg-dark-beige',
  'light-beige': 'bg-light-beige',
  'black-100': 'bg-black-100',
  'warm-cream': 'bg-warm-cream',
} as const

const openedModalTypes = {
  underMobileHeader: 0,
  sideBarRight: 0,
}

const PlainModal: React.FC<PlainModalProps> = (props) => {
  const {
    children,
    className,
    containerClassName: containerClassNameProps,
    overlayClassName,
    underMobileHeader = false,
    overlayClosable = true,
    withCloseButton = true,
    withAnimation = true,
    shouldCloseOnPathnameChange = true,
    closeButtonIcon,
    closeButtonClassName,
    closeModal,
    bgColor = 'gray-10',
    type = 'default',
    'data-testid': dataTestId,
  } = props

  const { isMobile } = useDevice()

  const pathname = usePathname()
  const initPathname = useRef(pathname)

  const uniqueId = useUniqueId()
  const titleId = `title_${uniqueId}`
  const textId = `text_${uniqueId}`
  const trapFocusId = `trapfocus_${uniqueId}`
  const modalBodyRef = useRef<HTMLDivElement>(null)

  useFreezeBodyScroll()

  const handleOverlayClick = useCallback(() => {
    if (overlayClosable) {
      closeModal(true)
    }
  }, [ overlayClosable, closeModal ])

  const handleCloseButtonClick = useCallback(() => {
    closeModal(true)
  }, [ closeModal ])

  const handleModalClick = useCallback((event) => {
    event.stopPropagation()
  }, [])

  useEffect(() => {
    const trapFocusElement = modalBodyRef.current

    // could be "null" if modal opened in iframe
    if (trapFocusElement) {
      const trapFocus = new TrapFocus(modalBodyRef.current, { withInitialFocus: false })

      trapFocusElement.focus()
      trapFocus.mount()

      return () => {
        trapFocus.unmount()
      }
    }
  }, [])

  // ATTN it's required only for some modals, otherwise overlay click closes all modals in a single click
  const useDocumentOverlayClick = isMobile && underMobileHeader

  useEffect(() => {
    if (!useDocumentOverlayClick) {
      return
    }

    // Delay until after the current call stack is empty,
    // in case this effect is being run while an event is currently bubbling.
    // In that case, we don't want to listen to the pre-existing event.
    const timeout = setTimeout(() => {
      document.addEventListener('click', handleOverlayClick)
    })

    return () => {
      clearTimeout(timeout)
      document.removeEventListener('click', handleOverlayClick)
    }
  }, [ handleOverlayClick, useDocumentOverlayClick ])

  useEffect(() => {
    if (shouldCloseOnPathnameChange && pathname !== initPathname.current) {
      closeModal(true) // // TODO should we call "onClose" in case of route change? - added on 07.12.2021 by sonatskiy
    }
  }, [ pathname, shouldCloseOnPathnameChange, closeModal ])

  useIsomorphicLayoutEffect(() => {
    if (!underMobileHeader || !isMobile) {
      return
    }

    openedModalTypes.underMobileHeader++

    const className = 'fixed-header'
    document.body.classList.add(className)

    return () => {
      openedModalTypes.underMobileHeader--

      if (!openedModalTypes.underMobileHeader) {
        document.body.classList.remove(className)
      }
    }
  }, [ isMobile, underMobileHeader ])

  useIsomorphicLayoutEffect(() => {
    if (type !== 'sidebar-right') {
      return
    }

    openedModalTypes.sideBarRight++
    const className = 'sidebar-right-open'

    document.body.classList.add(className)

    return () => {
      openedModalTypes.sideBarRight--

      if (!openedModalTypes.sideBarRight) {
        document.body.classList.remove(className)
      }
    }
  }, [ type ])

  // TODO refactor to tailwind completely to support overriding - added on 2023-12-22 by maddoger
  const containerClassName = twcx(
    s.container,
    withAnimation && s.animated,
    isMobile ? 'absolute inset-0' : 'flex items-center justify-center',
    {
      [s.defaultContainer]: type === 'default',
      [s.leftSidebarContainer]: type === 'sidebar-left',
      [s.rightSidebarContainer]: type === 'sidebar-right',
      [s.topFullscreenContainer]: type === 'fullscreen-top',
      [s.bottomContainer]: type === 'bottom',
    },
    containerClassNameProps
  )

  const modalBodyPaddingClassName = {
    default: isMobile ? 'pt-40 px-16 pb-16' : 'pt-48 px-40 pb-40',
    'sidebar-left': isMobile ? 'pt-40 px-16 pb-16' : 'pt-48 px-40 pb-40',
    'sidebar-right': isMobile ? 'pt-40 px-16 pb-16' : 'pt-48 px-40 pb-40',
    'fullscreen-top': isMobile ? 'pt-8 px-16 pb-16' : 'pt-48 px-0 pb-56',
    bottom: 'p-16',
  }

  // Think
  const modalBodyClassName = twcx(
    'relative flex flex-col',
    bgColorToClassName[bgColor],
    {
      [s.plainModal]: type === 'default',
      [s.sidebar]: type === 'sidebar-right' || type === 'sidebar-left',
      [s.fullscreen]: type === 'fullscreen-top',
    },
    modalBodyPaddingClassName[type],
    className
  )

  const finalOverlayClassName = twcx(
    'z-overlay',
    s.overlay,
    isMobile && underMobileHeader && s.underHeader,
    overlayClassName
  )

  return createPortal(
    <ModalContext.Provider value={{ titleId, textId }}>
      <Overlay
        className={finalOverlayClassName}
        withAnimation={withAnimation}
        onClick={useDocumentOverlayClick ? null : handleOverlayClick}
      >
        <div className={containerClassName}>
          <div
            id={trapFocusId}
            ref={modalBodyRef}
            className={modalBodyClassName}
            tabIndex={0}
            role="dialog"
            aria-modal="true"
            aria-labelledby={titleId}
            aria-describedby={textId}
            data-testid={dataTestId}
            onClick={handleModalClick}
          >
            {
              withCloseButton && (
                <CloseIconButton
                  className={twcx(isMobile ? 'right-16 top-16' : 'right-32 top-32', closeButtonClassName)}
                  icon={closeButtonIcon}
                  onClick={handleCloseButtonClick}
                />
              )
            }
            {children}
          </div>
        </div>
      </Overlay>
    </ModalContext.Provider>,
    document.getElementById('modals')
  )
}


export default PlainModal
